exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-an-appointment-js": () => import("./../../../src/pages/book-an-appointment.js" /* webpackChunkName: "component---src-pages-book-an-appointment-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-treatments-js": () => import("./../../../src/pages/our-treatments.js" /* webpackChunkName: "component---src-pages-our-treatments-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-treatments-archetype-js": () => import("./../../../src/pages/treatments/archetype.js" /* webpackChunkName: "component---src-pages-treatments-archetype-js" */),
  "component---src-pages-treatments-craniosacral-massage-calgary-js": () => import("./../../../src/pages/treatments/craniosacral-massage-calgary.js" /* webpackChunkName: "component---src-pages-treatments-craniosacral-massage-calgary-js" */),
  "component---src-pages-treatments-hot-stone-massage-calgary-js": () => import("./../../../src/pages/treatments/hot-stone-massage-calgary.js" /* webpackChunkName: "component---src-pages-treatments-hot-stone-massage-calgary-js" */),
  "component---src-pages-treatments-massage-therapy-calgary-js": () => import("./../../../src/pages/treatments/massage-therapy-calgary.js" /* webpackChunkName: "component---src-pages-treatments-massage-therapy-calgary-js" */),
  "component---src-pages-treatments-reiki-calgary-js": () => import("./../../../src/pages/treatments/reiki-calgary.js" /* webpackChunkName: "component---src-pages-treatments-reiki-calgary-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

